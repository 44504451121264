/* src/HomePage.css */

.homepage-content {
    text-align: center;
    background-color: #2B2F38;
    padding: 20px;
  }
  
  .navbar {
    display: flex;
    align-items: center;
    padding: 20px;
    background-color: #2B2F38;
    width: 100%;
  }
  
  .navbar .logo {
    width:10%;
    height:auto;
    max-width:300px;
  }

  .navbar ul {
    list-style: none;
    display: inline-flex;
    gap: 20px;
    margin: 0;
    padding: 0;
  }
  
  .navbar li {
    cursor: pointer;
    color: #ffffff;
    background-color: #506172;
    border-radius: 10px;
    margin-right: 20px; /* Espacement entre les éléments */
    padding: 10px 15px;
  }
  
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2B2F38;
    color: white;
  }
  
  .text-section {
    max-width: 50%;
    text-align: justify;
  }
  
  .text-section h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin: 0 0 20px;
    color: #ffffff;
  }
  
  .text-section p {
    font-size: 1rem;
    color: #5DBCD2;
  }
  
  .btn-primary {
    padding: 10px 20px;
    background-color: #008f4c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .testimonial {
    margin-top: 30px;
    text-align: left;
    color: white;
  }
  
  .stars {
    color: gold;
  }
  
  .image-section img {
    width: 500px;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  